import { logError } from "@crown/logging";

/** These functions safe to access both on the server and client side.
 */

declare const process: {
  browser: boolean;
  env: Record<string, string>;
};

/**
 * All environment variables accessed on the client need to be listed here.
 */
export const customEnvVars = [
  "APPTUS_CLUSTER_ID",
  "NODE_ENV",
  "RECAPTCHA_SITE_KEY",
  "INGRID_PRODUCT_SHIPPING_AUTH_TOKEN",
  "INGRID_PRODUCT_SHIPPING_URL",
  "INGRID_FAQ_WIDGET_URL",
  "INGRID_FAQ_WIDGET_TOKEN",
  "GTM_CONTAINER_ID",
  "POSTHOG_API_KEY",
];

export function getEnvVar(
  envVar: string,
  // null effectively means "optional"
  defaultValue: null
): string | null;
export function getEnvVar(envVar: string, defaultValue?: string): string;
export function getEnvVar(
  envVar: string,
  defaultValue?: string | null
): string | null {
  // warn locally during development that we need to whitelist client-side env vars
  if (
    process.browser &&
    ["development", "preview", "unittest"].includes(getEnvironment()) &&
    !customEnvVars.find((key) => key === envVar)
  ) {
    console.warn(
      "%c Missing client-side env variable:",
      "color: palevioletred;",
      `"${envVar}"`
    );
  }

  let res: string | null | undefined = process.browser
    ? (window["env"] || {})[envVar]
    : process.env[envVar];

  if (
    res === undefined &&
    defaultValue !== undefined &&
    (envVar == "NODE_ENV" ||
      envVar == "UNITTEST" ||
      defaultValue === null ||
      !(getEnvironment() == "production" && !getEnvVar("UNITTEST", null)))
  ) {
    res = defaultValue;
  }

  if (res !== undefined) {
    return res;
  } else {
    throw new Error(`Please set environment variable ${envVar}`);
  }
}

export function getEnvVarScriptTag(
  customVars?: string[],
  getValue?: (envVar: string) => string | undefined
) {
  if (process.browser) {
    return "";
  }

  const clientSideEnvVars = ["NODE_ENV", "HEROKU_RELEASE_VERSION"]
    .concat(customVars || [])
    .concat(
      // feels unsafe to expose in production
      process.env.NODE_ENV === "production"
        ? []
        : ["HEROKU_APP_NAME", "HEROKU_BRANCH", "PREVIEW"]
    );

  const env: Record<string, string> = clientSideEnvVars.reduce(
    (env, envVar) => ({
      ...env,
      [envVar]: getValue ? getValue(envVar) : process.env[envVar],
    }),
    {}
  );

  return `<script>window.env = ${JSON.stringify(env)}</script>`;
}

export function getApptusClusterId() {
  return getEnvVar("APPTUS_CLUSTER_ID", "w042032D1");
}

export function getIngridProductShippingAuthToken() {
  return getEnvVar(
    "INGRID_PRODUCT_SHIPPING_AUTH_TOKEN",
    "ingrid-http-auth-token:1990e59c-a753-40ab-bee0-2cfcc45c086e"
  );
}

export function getIngridProductShippingUrl() {
  return getEnvVar(
    "INGRID_PRODUCT_SHIPPING_URL",
    "https://cdn-stage.ingrid.com/product-page-widget/bootstrap.js"
  );
}

export function getIngridFaqWidgetUrl() {
  return getEnvVar(
    "INGRID_FAQ_WIDGET_URL",
    "https://cdn-stage.ingrid.com/faq-widget/bootstrap.js"
  );
}

export function getIngridFaqWidgetAuthToken() {
  return getEnvVar(
    "INGRID_FAQ_WIDGET_TOKEN",
    "ingrid-http-auth-token:97475fa3-dce7-439d-aa87-45d52026de0e"
  );
}

export function getEnvironment():
  | "development"
  | "production"
  | "staging"
  // preview: do not cache any data for long
  | "preview"
  // unittest: cache data but not between runs
  | "unittest" {
  const env = typeof document !== "undefined" ? window["env"] : process.env;

  let result = env?.UNITTEST
    ? "unittest"
    : env?.PREVIEW
    ? "preview"
    : (env?.NODE_ENV as any);

  if (!result) {
    logError(
      `Found no NODE_ENV parameter (env: ${!!env}). Assuming development.`
    );

    result = "development";
  }

  return result;
}

export function getRecaptchaSiteKey() {
  return getEnvVar(
    "RECAPTCHA_SITE_KEY",
    "6LdobzUgAAAAALUleLzScpRb9ENV_olooCeIdRwA"
  );
}

export function getGtmContainerId() {
  return getEnvVar("GTM_CONTAINER_ID", "GTM-MLMBPV3");
}

export function getPosthogApiKey() {
  return getEnvVar(
    "POSTHOG_API_KEY",
    "phc_vl8PPScwXAPVYe2Q5HVyHzHYoYBazHsZf2KjgkXrr0u"
  );
}

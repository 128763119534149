import { GroupedError, waitForValue } from "@crown/data";
import { debug, logError } from "@crown/logging";
import { getEnvironment } from "../env-vars";
import type { LocaleConfig } from "../locale";
import type { Storefront } from "../types/Storefront";

function getGoogleAnalyticsClientId(
  /* TODO: GTM should be configured to return a trackingId on dev too. then this can be made required */
  googleAnalyticsProperty?: string
): string | undefined {
  if (!process.browser) {
    return undefined;
  }

  const ga = window["ga"];

  if (typeof ga?.getAll == "function") {
    const trackers = ga?.getAll();

    if (trackers && trackers.length > 0) {
      const tracker = googleAnalyticsProperty
        ? trackers.find(
            (tracker: any) =>
              tracker.get("trackingId") === googleAnalyticsProperty
          )
        : trackers[0];

      if (tracker) {
        return tracker.get("clientId");
      } else if (getEnvironment() == "production") {
        logError(
          new GroupedError(
            `No tracker for property ${googleAnalyticsProperty}, only ${trackers
              .map((tracker: any) => tracker.get("trackingId"))
              .join(", ")}.`,
            "noTrackerFound"
          )
        );
      }
    } else {
      logError(`Could not find any GA trackers.`);
    }
  } else {
    logError(`GTM not running.`);
  }
}

export async function setCartClientMetadata(
  cartStore: Storefront.CartStore,
  locale: LocaleConfig
) {
  /* TODO: it would make more sense to configure the GA property through environment variables */
  const { googleAnalyticsProperty } = locale;

  const gaClientId = getGoogleAnalyticsClientId(googleAnalyticsProperty);

  const deviceType =
    typeof window != "undefined" && window.innerWidth > 768
      ? "DESKTOP"
      : "MOBILE";

  const data = await waitForValue(cartStore);

  if (data.cartId != "empty") {
    if (process.isDevelopment) {
      debug(`Storing Google Analytics client ID "${gaClientId}" in cart`);
    }

    await cartStore.setClientMetadata({
      gaClientId,
      deviceType,
    });
  }
}
